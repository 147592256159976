var render = function render(){var _vm=this,_c=_vm._self._c;return _c('loader',_vm._b({},'loader',{ loading: _vm.loading },false),[_c('columns',[_c('column',[_c('page-heading',{attrs:{"heading":"Classifications","sub-heading":"Classifications allow you to categorise work orders, equipment and checklist types within your account."}})],1)],1),_c('columns',[_c('column',[_c('div',{staticClass:"box is-flex is-flex-column is-justify-content-center is-align-items-center is-fullheight"},[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(_vm.classifications.work_order_classifications))]),_c('span',{staticClass:"subtitle has-text-weight-light"},[_vm._v("Work Order Classifications")]),_c('router-link',{staticClass:"is-flex is-align-items-center",attrs:{"to":{
                    name: 'work-order-classifications'
                }}},[_vm._v("Manage Classifications "),_c('icon',{attrs:{"icon":"arrow-right"}})],1)],1)]),_c('column',[_c('div',{staticClass:"box is-flex is-flex-column is-justify-content-center is-align-items-center is-fullheight"},[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(_vm.classifications.equipment_classifications))]),_c('span',{staticClass:"subtitle has-text-weight-light"},[_vm._v("Equipment Classifications")]),_c('router-link',{staticClass:"is-flex is-align-items-center",attrs:{"to":{
                    name: 'equipment-classifications'
                }}},[_vm._v("Manage Classifications "),_c('icon',{attrs:{"icon":"arrow-right"}})],1)],1)]),_c('column',[_c('div',{staticClass:"box is-flex is-flex-column is-justify-content-center is-align-items-center is-fullheight"},[_c('h4',{staticClass:"title"},[_vm._v(_vm._s(_vm.classifications.checklist_classifications))]),_c('span',{staticClass:"subtitle has-text-weight-light"},[_vm._v("Checklist Classifications")]),_c('router-link',{staticClass:"is-flex is-align-items-center",attrs:{"to":{
                    name: 'checklist-classifications'
                }}},[_vm._v("Manage Classifications "),_c('icon',{attrs:{"icon":"arrow-right"}})],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }