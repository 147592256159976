<template>
<loader v-bind="{ loading }">
    <columns>
        <column>
            <page-heading
                heading="Classifications"
                sub-heading="Classifications allow you to categorise work orders, equipment and checklist types within your account."
            />
        </column>
    </columns>
    <columns>
        <column>
            <div class="box is-flex is-flex-column is-justify-content-center is-align-items-center is-fullheight">
                <h4 class="title">{{ classifications.work_order_classifications }}</h4>
                <span class="subtitle has-text-weight-light">Work Order Classifications</span>
                <router-link class="is-flex is-align-items-center" :to="{
                    name: 'work-order-classifications'
                }">Manage Classifications <icon icon="arrow-right"/>
                </router-link>
            </div>
        </column>
        <column>
            <div class="box is-flex is-flex-column is-justify-content-center is-align-items-center is-fullheight">
                <h4 class="title">{{ classifications.equipment_classifications }}</h4>
                <span class="subtitle has-text-weight-light">Equipment Classifications</span>
                <router-link class="is-flex is-align-items-center" :to="{
                    name: 'equipment-classifications'
                }">Manage Classifications <icon icon="arrow-right"/>
                </router-link>
            </div>
        </column>
        <column>
            <div class="box is-flex is-flex-column is-justify-content-center is-align-items-center is-fullheight">
                <h4 class="title">{{ classifications.checklist_classifications }}</h4>
                <span class="subtitle has-text-weight-light">Checklist Classifications</span>
                <router-link class="is-flex is-align-items-center" :to="{
                    name: 'checklist-classifications'
                }">Manage Classifications <icon icon="arrow-right"/>
                </router-link>
            </div>
        </column>
    </columns>
</loader>
</template>
<script>
import { classification as backend } from '@/api'
export default {

    data: () => ({
        loading: true,
        classifications: {
            work_order_classifications: 0,
            equipment_classifications: 0,
            checklist_classifications: 0,
        }
    }),

    beforeCreate() {
        backend.loadOverview(({data}) => {
            this.classifications = data
            this.loading = false
        }, () => this.loading = false)
    }

}
</script>